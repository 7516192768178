import { LogFancy, reduxConnectPipeline } from '../libraries/Helpers'
import TreeComponent from './TreeComponent'
import { actionModalHide, actionModalShow } from '../redux/modules/action'
import { selectCompletedToday } from '../redux/modules/node'


function mapStateToProps(state) {
    LogFancy.tag('mapStateToProps').print('Tree.mapStateToProps')

    const isLoaded = state.node.initialDataLoaded && !state.node.isFetching

    let rootId, rootNodeId, rightRootId, rightRootNodeId

    if (isLoaded) {
        rootId          = state.node.rootId
        rootNodeId      = state.node.rootNodeId
        rightRootId     = state.node.rightRootId
        rightRootNodeId = state.node.rightRootNodeId
    }

    return {
        rootId: rootId,
        rootNodeId: rootNodeId,
        rightRootId: rightRootId,
        rightRootNodeId: rightRootNodeId,
        isFetching: !isLoaded,
        showCompleted: state.node.showCompleted,
        completedToday: selectCompletedToday(state),
        isActionModalActive: state.action.isActive,
    }
}

export default reduxConnectPipeline(
    TreeComponent,
    // areStatesEqual()
    (next, prev) => (
        next.node.initialDataLoaded === prev.node.initialDataLoaded &&
        next.node.isFetching === prev.node.isFetching &&
        next.node.rootId === prev.node.rootId &&
        next.node.rootNodeId === prev.node.rootNodeId &&
        next.node.rightRootNodeId === prev.node.rightRootNodeId &&
        next.node.showCompleted === prev.node.showCompleted &&
        next.node.initialDataLoaded === prev.node.initialDataLoaded &&
        next.action.isActive === prev.action.isActive
    ),
    mapStateToProps,
    // mapDispatchToProps()
    {
        actionModalShow: actionModalShow,
        actionModalHide: actionModalHide,
    },
)
