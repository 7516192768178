import React, { Component } from 'react'
import Spinner from './_utils/Spinner'
import { LogFancy } from '../libraries/Helpers'

class LoginComponent extends Component {
    constructor(props) {
        super(props)
        
        this.state = {
            email   : '',
            password: '',
        }
        
        this.handleInputChange = this.handleInputChange.bind(this)
        this._submitHandler    = this._submitHandler.bind(this)
    }
    
    handleInputChange(event) {
        const target = event.target
        const value  = target.type === 'checkbox' ? target.checked : target.value
        const name   = target.name
        
        this.setState({
            [name]: value,
        })
    }
    
    componentDidUpdate(prevProps) {
        if (this.props.auth.isLoggedIn) {
            this.props.history.push('/home')
        }
    }
    
    _submitHandler() {
        this.props.attemptLogin(this.state.email, this.state.password)
    }
    
    render() {
        
        LogFancy.tag('render').red('LoginComponent.render()')
        
        return (
            <div className="login container vh-100">
                <div className="row vh-100 align-items-center justify-content-center">
                    <div className="col-12 col-sm-8 col-lg-6 col-xl-4 pb-5">
                        <div className="card text-center">
                            {/* ------------------------------------------------------------------------------------------------------------------ */}
                            <div className="card-body">
                                <h5 className="card-title">Login to FinalApp</h5>
                                
                                {this.props.auth.loginRequest.isFetching && <Spinner size="3x"/>}
                                
                                {this.props.auth.loginRequest.error &&
                                <div className="alert alert-danger" role="alert">
                                    <b>{this.props.auth.loginRequest.error.title}</b>
                                    <br/>
                                    {this.props.auth.loginRequest.error.detail}
                                </div>
                                }
                                
                                <p className="card-text">Enter your credentials to login.</p>
                                
                                <form action="/">
                                    <div className="form-group">
                                        <input type="text" name="email" className="form-control" placeholder="Email" autoComplete="username"
                                               onChange={this.handleInputChange}/>
                                    </div>
                                    
                                    <div className="form-group">
                                        <input type="password" name="password" className="form-control" placeholder="Password"
                                               autoComplete="current-password" onChange={this.handleInputChange}/>
                                    </div>
                                </form>
                                
                                <div className="btn btn-success w-100" onClick={this._submitHandler}>
                                    Login
                                </div>
                            </div>
                            {/* ------------------------------------------------------------------------------------------------------------------ */}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default LoginComponent
