import React, { useEffect, useRef } from 'react'
import { isDeepEqual, LogFancy } from '../libraries/Helpers'

function ActionComponent(props) {

    const filterInput = useRef()

    useEffect(() => {
        // focus to the input if this is the active node
        if (props.isActive) {
            filterInput.current.focus()
        }
    }, [props.isActive])

    function handleClick(e) {
        if (e.target.matches('div.modal,button.close,button.close *')) {
            props.actionModalHide()
        }
    }

    function handleQueryChange(e) {
        props.queryChange(e.target.value)
    }

    const numResults = props.results.length + (props.results.length >= 20 ? '+' : '')

    LogFancy.tag('render').red('ActionComponent.render()')

    return (
        <div>
            <div className="modal" style={{display: props.isActive ? 'block' : 'none'}} onClick={handleClick}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <input type="text" className="form-control" ref={filterInput} value={props.query} onChange={handleQueryChange}/>
                            <button type="button" className="close" onClick={handleClick}>
                                <span>&times;</span>
                            </button>
                        </div>
                        <div className="modal-body modal-body-scroll">
                            <ul>
                                {props.query && props.results && props.results.map(node =>
                                    <li key={node.id}>{node.attributes.title}</li>,
                                )}
                            </ul>
                        </div>
                        <div className="modal-footer">
                            <span>Found {numResults} results.</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-backdrop show" style={{display: props.isActive ? 'block' : 'none'}}></div>
        </div>
    )
}

/* ------------------------------------------------------------------------------------------------------------------------------ should Update -+- */
function shouldUpdate(props, nextProps) {
    LogFancy.tag('shouldUpdate').blue('ActionComponent.shouldUpdate()')

    return isDeepEqual([
        props.query,
        props.results,
        props.isActive,
    ], [
        nextProps.query,
        nextProps.results,
        nextProps.isActive,
    ])
}

export default React.memo(ActionComponent, shouldUpdate)
