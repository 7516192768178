import { connect } from 'react-redux'

import ActionComponent from './ActionComponent'
import action, { actionModalHide, actionModalShow, selectFilteredNodes } from '../redux/modules/action'
import { LogFancy } from '../libraries/Helpers'

function mapStateToProps(state) {
    const filteredNodes = selectFilteredNodes(state)
    LogFancy.tag('mapStateToProps').print('Action.mapStateToProps')
    return {
        isActive: state.action.isActive,
        query: state.action.query,
        results: filteredNodes,
    }
}


export default connect(mapStateToProps, {
        actionModalShow: actionModalShow,
        queryChange: action.actions.queryChange,
        actionModalHide: actionModalHide,
    },
)(ActionComponent)
