import watch from 'redux-watch'
import { getDeepDiff, LogFancy } from '../../libraries/Helpers'
import { store } from '../../index'
import syncBuffer from '../modules/syncBuffer'
import moment from 'moment'

export const initWatchers = () => {
    /* --------------------------------------------------------------------------------------------------------------------------- boot Watcher -+- */
    const bootWatcher = watch(store.getState, 'node.initialDataLoaded')

    store.subscribe(bootWatcher((newVal, oldVal) => {
        if (newVal === true) startWatchers()
    }))
}

const startWatchers = () => {

    /* ------------------------------------------------------------------------------------------------------------------------- node Watcher -+- */
    const nodeWatcher = watch(store.getState, 'node.nodes')

    store.subscribe(nodeWatcher((newVal, oldVal) => {
        console.time('nodeWatcher')
        let diff = getDeepDiff(oldVal, newVal)

        for (const id in diff) {
            if (!diff.hasOwnProperty(id)) continue

            if (diff[id] === undefined) {

                store.dispatch(syncBuffer.actions.addToSyncBuffer({
                    identifier: `node-${id}-delete`,
                    payload: {
                        event: 'model_delete',
                        time: moment().format('YYYY-MM-DD HH:mm:ss'),
                        data: {
                            type: 'items',
                            id: id,
                        },
                    },
                }))

            } else {
                // Changes were undone before committing, cancel the action.
                // This happens when multiple dispatch calls modify attributes, but the last call sets values to the initial state.
                // Therefore nothing changes when the batch update invokes subscribers.
                if (diff[id].attributes === undefined) return false

                store.dispatch(syncBuffer.actions.addToSyncBuffer({
                    identifier: `node-${id}-update`,
                    payload: {
                        event: 'model_update',
                        time: moment().format('YYYY-MM-DD HH:mm:ss'),
                        data: {
                            type: 'items',
                            id: id,
                            ...diff[id],
                        },
                    },
                }))
            }

        }
        console.timeEnd('nodeWatcher')
    }))

    /* --------------------------------------------------------------------------------------------------------------------------- link Watcher -+- */
    const linkWatcher = watch(store.getState, 'node.links')

    store.subscribe(linkWatcher((newVal, oldVal, path) => {
        console.time('linkWatcher')

        LogFancy.blue(`Link Change: ${path}`)

        let diff = getDeepDiff(oldVal, newVal)
        for (const id in diff) {
            if (!diff.hasOwnProperty(id)) continue

            if (diff[id] === undefined) {
                LogFancy.blue(`Link DELETE: ${id}`)
                store.dispatch(syncBuffer.actions.addToSyncBuffer({
                    identifier: `link-${id}-delete`,
                    payload: {
                        event: 'model_delete',
                        time: moment().format('YYYY-MM-DD HH:mm:ss'),
                        data: {
                            type: 'links',
                            id: id,
                        },
                    },
                }))

            } else {
                LogFancy.blue(`Link UPDATE: ${id}`)

                store.dispatch(syncBuffer.actions.addToSyncBuffer({
                    identifier: `link-${id}-update`,
                    payload: {
                        event: 'model_update',
                        time: moment().format('YYYY-MM-DD HH:mm:ss'),
                        data: {
                            type: 'links',
                            id: id,
                            ...diff[id],
                        },
                    },
                }))

            }
        }
        console.timeEnd('linkWatcher')
    }))

}
