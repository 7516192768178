import ApiClient from '../../libraries/ApiClient'
import { createAsyncRequest } from '../../libraries/Helpers'

/* ------------------------------------------------------------------------------------------------------------------------------------------------ */
/* -------------------------------------------------------------------------------------------------------------------- REQUEST SLICE FRAGMENTS -+- */
/* ------------------------------------------------------------------------------------------------------------------------------------------------ */

/* ----------------------------------------------------------------------------------------------------------------------- initial Data Request -+- */
export const initialDataRequest = createAsyncRequest({
    slice: 'node',
    name: 'initialDataRequest',
    callback: async (args, thunkApi) => {
        const response = await ApiClient.getRequest('initial-data')
        return response.data
    },
    fulfilled: (state, action) => {
        state.nodes = {}
        state.links = {}

        let rootNodeId = null

        for (const item of action.payload.data) {
            state.nodes[item.id] = item

            // TODO: this is temporary and covers up some issues. Remove this and find the source of the problem.
            if (!item) continue

            // pick the root node id
            if (item.attributes.type === 'root') {
                rootNodeId = item.id
            }
        }

        if (action.payload.included) {
            for (const item of action.payload.included) {

                // pick and set the root node LINK id
                if (item.attributes.child_id === rootNodeId) {
                    state.rootId = item.id
                }

                state.links[item.id] = item
            }
        }

        state.rootNodeId        = rootNodeId
        state.initialDataLoaded = true
    },
})

export const {request: fetchInitialData} = initialDataRequest

/* ------------------------------------------------------------------------------------------------------------------------------------------------ */
