import React, { useEffect, useRef } from 'react'
import Node from './Node'
import { Cache, getParent, hash53, isDeepEqual, LogFancy, useProxyRef } from '../libraries/Helpers'
import UiNode from '../libraries/UiNode'
import { useSelector } from 'react-redux'


function ListComponent(props) {

    /**
     * @type {{current: HTMLElement}} obj
     */
    const list = useRef()

    /**
     * @type {{activeNode: ?UiNode}}
     */
    const state = useProxyRef({
        activeNode: null,
    })

    /* ----------------------------------------------------------------------------------------------------------------------------- use Effect -+- */
    useEffect(() => {
        const _list = list.current

        _list.addEventListener('selectionchange', handleSelection)
        _list.addEventListener('keydown', handleCaptureKeydown, true)
        _list.addEventListener('focusin', handleFocusIn)

        return () => {
            _list.removeEventListener('keydown', handleCaptureKeydown, true)
            _list.removeEventListener('focusin', handleFocusIn)
            _list.removeEventListener('selectionchange', handleSelection)
        }
    })

    /* --------------------------------------------------------------------------------------------------------------------------- use Selector -+- */
    useSelector(_state => {
        const hash                 = _state.node.activeNodeHash
        const isActionWindowActive = _state.action.isActive
        if (!hash || isActionWindowActive) return true

        window.requestAnimationFrame(() => {
            const targetNode = list.current.querySelector(`.ygg-node[data-hash="${hash}"]`)

            if (!targetNode) return false

            console.log('focus')

            state.activeNode = new UiNode(targetNode, props.rootNodeId)
            state.activeNode.focus()

            const activeSelection = Cache.get('active-selection')
            const focusedNode     = state.activeNode.getTitleInput()?.childNodes?.[0]

            if (focusedNode && activeSelection) {
                const range = document.createRange()
                range.setStart(focusedNode, activeSelection.selectionStart)
                range.setEnd(focusedNode, activeSelection.selectionEnd)

                var s = window.getSelection()
                s.removeAllRanges()
                s.addRange(range)
            }
        })

        // Prevent re-render by returning exact the same value every time this function runs.
        return true
    })

    /* ----------------------------------------------------------------------------------------------------------------------- handle Selection -+- */
    function handleSelection() {
        Cache.put('active-selection', {
            selectionStart: document.getSelection().anchorOffset,
            selectionEnd: document.getSelection().focusOffset
        })
    }

    /* ------------------------------------------------------------------------------------------------------------------------ handle Focus In -+- */
    /**
     * This event handler is used to capture the focused node, when the focus changes.
     *
     * @param e
     * @returns {boolean}
     */
    function handleFocusIn(e) {
        e.stopPropagation()
        window.requestAnimationFrame(() => {
            Cache.put('active-selection', {
                selectionStart: document.getSelection().anchorOffset,
                selectionEnd: document.getSelection().focusOffset
            })
        })

        tryUpdateFocusedNode(e.target)
    }

    /* -------------------------------------------------------------------------------------------------------------- try Update Focused Node -+- */
    function tryUpdateFocusedNode(el) {
        const node = getParent(el, '.ygg-node')

        if (!node || !node.matches('.ygg-node')) return false

        state.activeNode = new UiNode(node, props.rootNodeId)

        props.focusToNode(state.activeNode.instanceHash)
    }

    /* ----------------------------------------------------------------------------------------------------------------- handle Capture Keydown -+- */
    function handleCaptureKeydown(e) {
        window.requestAnimationFrame(() => {
            Cache.put('active-selection', {
                selectionStart: document.getSelection().anchorOffset,
                selectionEnd: document.getSelection().focusOffset
            })
        })

        let keyCode = e.keyCode || e.which

        switch (keyCode) {
            // [BACKSPACE] key
            case 8:
                if ((e.shiftKey && e.ctrlKey) || state.activeNode.getValue().length < 1) {
                    e.preventDefault()
                    e.stopPropagation()

                    // remove the to do item on [Backspace] when the input is empty;
                    props.deleteNode(state.activeNode)
                }
                break

            // [Tab] key
            case 9:
                e.preventDefault()
                e.stopPropagation()

                if (!e.shiftKey) {
                    props.indentNode(state.activeNode)
                } else {
                    props.outdentNode(state.activeNode)
                }
                break

            // [ENTER] key
            case 13:
                e.preventDefault()
                e.stopPropagation()

                if (e.ctrlKey) {
                    // set completed
                    props.toggleCompleted(state.activeNode)
                    props.selectNextNode(state.activeNode)
                } else {
                    // create node
                    props.createNode(state.activeNode)
                }
                break

            // [SPACE] key
            case 32:
                if (e.ctrlKey) {
                    e.preventDefault()
                    e.stopPropagation()

                    // Hide/show sub-nodes of the active node.
                    props.toggleSubNodes(state.activeNode)
                }
                break

            // [<-] key
            case 37:
                if (e.shiftKey && e.altKey) {
                    e.preventDefault()
                    e.stopPropagation()

                    props.outdentNode(state.activeNode)

                } else if (e.ctrlKey && e.altKey) {
                    e.preventDefault()
                    e.stopPropagation()

                    props.removeFromRight(state.activeNode)

                } else if (e.altKey) {
                    e.preventDefault()
                    e.stopPropagation()

                    props.zoomOut(state.activeNode)
                }
                break

            // [->] key
            case 39:
                if (e.shiftKey && e.altKey) {
                    e.preventDefault()
                    e.stopPropagation()

                    props.indentNode(state.activeNode)

                } else if (e.ctrlKey && e.altKey) {
                    e.preventDefault()
                    e.stopPropagation()

                    props.addToRight(state.activeNode)

                } else if (e.altKey) {
                    e.preventDefault()
                    e.stopPropagation()

                    props.zoomIn(state.activeNode)
                }
                break

            // [UP] key
            case 38:
                e.preventDefault()
                e.stopPropagation()

                if (e.shiftKey && e.altKey) {
                    // Move node up.
                    props.moveNodeUp(state.activeNode)
                } else {
                    // Select previous node.
                    props.selectPrevNode(state.activeNode)
                }
                break

            // [DOWN] key
            case 40:
                e.preventDefault()
                e.stopPropagation()

                if (e.shiftKey && e.altKey) {
                    // Move node down.
                    props.moveNodeDown(state.activeNode)
                } else {
                    props.selectNextNode(state.activeNode)
                }
                break

            // [M] key
            case 77:
                if (e.ctrlKey) {
                    e.preventDefault()
                    e.stopPropagation()

                    props.nodeSetType(state.activeNode)
                }
                break

            // [O] key
            case 79:
                if (e.ctrlKey) {
                    e.preventDefault()
                    e.stopPropagation()

                    props.toggleShowCompleted()
                }
                break

            // skip default
        }
    }

    /* ----------------------------------------------------------------------------------------------------------------- handle Capture Keydown -^- */


    /* --------------------------------------------------------------------------------------------------------------------------------- render -+- */
    LogFancy.tag('render').red('ListComponent.render()')

    return (
        <div className="ygg-node ygg-root"
             ref={list}
             onClickCapture={handleSelection}
             data-node-id={props.node.id}
             data-hash={hash53(props.node.id)}
        >
            <span className="pb-3" dangerouslySetInnerHTML={{__html: props.node.attributes.title}}></span>
            <div className="ygg-sub-nodes">
                {props.links.map(link =>
                    <Node
                        key={link.id}
                        id={link.attributes.child_id}
                        linkId={link.id}
                        rootNodeId={props.rootNodeId}
                        hash={hash53(hash53(props.node.id) + link.attributes.child_id)}
                        showTitles={props.showTitles}
                    />,
                )}
            </div>
        </div>
    )
}

/* ------------------------------------------------------------------------------------------------------------------------------ should Update -+- */
function shouldUpdate(props, nextProps) {
    LogFancy.tag('shouldUpdate').blue('ListComponent.shouldUpdate()')

    return isDeepEqual([
        props.links,
        props.node,
        props.rootId,
        props.viewRootId,
    ], [
        nextProps.links,
        nextProps.node,
        nextProps.rootId,
        nextProps.viewRootId,
    ])
}

export default React.memo(ListComponent, shouldUpdate)
