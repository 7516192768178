import { createAsyncThunk } from '@reduxjs/toolkit'
import { connect } from 'react-redux'
import React, { useRef } from 'react'

export const consoleHr = (message) => {
    message = ' ' + message

    while (message.length < 85) {
        message = '-' + message
    }

    return message
}

/* --------------------------------------------------------------------------------------------------------------------------------------- pipe -+- */
export const pipe = (x, ...args) => (...fns) => fns.reduce((v, f) => f(v, ...args), x)


/* ---------------------------------------------------------------------------------------------------------------------------------- is Object -+- */
export const isObject = (item) => {
    return (item && typeof item === 'object' && !Array.isArray(item))
}

/* ------------------------------------------------------------------------------------------------------------------------------ resolve Proxy -+- */
export const resolveProxy = (proxyItem) => {

    if (typeof proxyItem === 'object')
        proxyItem = Object.assign({}, proxyItem)

    if (typeof proxyItem === 'object')
        for (const itemId in proxyItem) {
            if (!proxyItem.hasOwnProperty(itemId)) continue

            proxyItem[itemId] = resolveProxy(proxyItem[itemId])
        }

    return proxyItem
}

/* --------------------------------------------------------------------------------------------------------------------------------------- pipe -+- */
export const mergeDeep = (target, ...sources) => {
    if (!sources.length) return target
    const source = sources.shift()

    if (isObject(target) && isObject(source)) {
        for (const key in source) {
            if (isObject(source[key])) {
                if (!target[key]) Object.assign(target, {[key]: {}})
                mergeDeep(target[key], source[key])
            } else {
                Object.assign(target, {[key]: source[key]})
            }
        }
    }

    return mergeDeep(target, ...sources)
}


/* ------------------------------------------------------------------------------------------------------------------------------ get Deep Diff -+- */
export const getDeepDiff = (old, updated) => {
    if (old === updated) return false
    const type = typeof old

    // item is completely different
    if (type !== typeof updated) return updated

    let diff = {}

    // simple comparison
    if (type !== 'object' && type !== 'array') {
        // value hasn't new. Return undefined
        if (old === updated) return undefined
        // return the new value
        return updated
    }

    // recursive comparison for arrays and objects
    for (const id in old) {
        if (!old.hasOwnProperty(id)) continue

        if (old[id] !== updated[id]) {
            let childDiff = getDeepDiff(old[id], updated[id])

            // record changes
            diff[id] = childDiff
        }
    }

    // recursive comparison for arrays and objects
    for (const id in updated) {
        if (!updated.hasOwnProperty(id)) continue

        if (updated[id] !== old[id] && old[id] === undefined) {
            // record newly added item
            diff[id] = updated[id]
        }
    }

    return isEmpty(diff) ? undefined : diff
}

/* --------------------------------------------------------------------------------------------------------------------- redux Connect Pipeline -+- */
/**
 * A pipeline for connecting redux state with react components.
 * Encapsulates the usage of `react-redux.connect()` along with `React.memo()` for determining whether to re-render a component.
 *
 * ---
 *
 * PROBLEM:
 * -------
 * - `react-redux.connect()` argument `mapStateToProps()` is called for ALL INSTANCES of a connected component, when ANY part of the GLOBAL state changes.
 * That's practically one function call per each component instance, for every `dispatch()` call!
 * That becomes expensive, really quick.
 * - Our `mapStateToProps()` can be expensive to run very very often.
 * - Re-rendering components are expensive. Especially when there are a lot of components.
 *
 * SOLUTION:
 * --------
 * - `react-redux.connect()` argument `options` accepts `areStatesEqual()` check via callback function.
 * There we can limit the `mapStateToProps()` calls to changes on a specific SUBSECTION of the global state.
 * We can check whether a small part of the global state changed - the part that is used for this specific component - and in most cases, we quit the expensive diffing early and cheaply.
 * Returning TRUE from the callback will cancel any further the diffing checks and re-render.
 * - `mapStateToProps` further narrows down the state, computes and derives data obtained from state where necessary.
 * - `mapDispatchToProps` has access to `redux.dispatch()`, and provides action creators to the component.
 * - `React.memo()` accepts argument `shouldComponentUpdate` in which we can deep diff the data generated in the previous steps,
 * and determine whether to re-render the component, or serve the cached version from the previous render.
 *
 * @param Component The component we want to connect to the redux store.
 * @param areStatesEqual A callback to check whether the relevant subsection of the global scope changed.
 * This will be run once on every global state update, and the result will cached and used for every instance of the component.
 * As this reduces the check to once per component TYPE (not once for every instance!), a lot of time is saved when the state change is not relevant to this component type, and the component type has many instances.
 * @param mapStateToProps
 * @param mapDispatchToProps
 * @param shouldComponentUpdate
 *
 * @see https://react-redux.js.org/api/connect#connect-parameters connect() function parameters in React Redux Documentation
 *
 * @returns {ConnectedComponent<React.NamedExoticComponent<object>|*, DistributiveOmit<GetLibraryManagedProps<React.NamedExoticComponent<object>|*>, keyof Shared<DispatchProp<AnyAction>, GetLibraryManagedProps<React.NamedExoticComponent<object>|*>>>>}
 */
export function reduxConnectPipeline(Component, areStatesEqual, mapStateToProps, mapDispatchToProps, shouldComponentUpdate) {

    let prevState, prevResult

    function memoizedAreStatesEqual(next, prev) {
        if (prevState !== next) {
            prevState  = next
            prevResult = areStatesEqual(next, prev)
        }

        return prevResult
    }

    const MemoizedComponent = typeof shouldComponentUpdate === 'function' ? React.memo(Component, shouldComponentUpdate) : Component

    // https://react-redux.js.org/api/connect#options-object
    return connect(mapStateToProps, mapDispatchToProps, null, {
        areStatesEqual: memoizedAreStatesEqual,
    })(MemoizedComponent)
}

/* ------------------------------------------------------------------------------------------------------------------ make Function With Buffer -+- */
export function makeFunctionWithBuffer(callback, milliseconds) {
    let timer

    return (...args) => {
        clearTimeout(timer)
        timer = setTimeout(() => {
            callback(...args)
        }, milliseconds)
    }
}


/* --------------------------------------------------------------------------------------------------------------------------------- get Parent -+- */
/**
 *
 * @param {HTMLElement} element
 * @param {string|HTMLElement} targetParent
 * @returns {HTMLElement}
 */
export function getParent(element, targetParent = null) {
    let parent         = element.parentElement
    let stringSelector = typeof targetParent === 'string'

    // Traverse up the DOM until finding the target parent.
    // Or all the way up to the document.
    while (parent !== document) {
        if (stringSelector) {
            if (parent.matches && parent.matches(targetParent)) break
        } else {
            if (parent === targetParent) break
        }

        parent = parent.parentElement
    }

    return parent
}

/* --------------------------------------------------------------------------------------------------------------------------------- array Move -+- */
/**
 * Moves an element inside an array.
 *
 * @source https://stackoverflow.com/a/6470794/4306828
 * @param arr
 * @param fromIndex
 * @param toIndex
 */
export function arrayMove(arr, fromIndex, toIndex) {
    const element = arr[fromIndex]
    arr.splice(fromIndex, 1)
    arr.splice(toIndex, 0, element)
}

/* ----------------------------------------------------------------------------------------------------------------------------- memoize By Key -+- */
export const memoizeByKey = (key, obj, callback) => {
    if (!memoizeByKey.map) memoizeByKey.map = new WeakMap()

    let valueStore = []

    if (memoizeByKey.map.has(obj)) valueStore = memoizeByKey.map.get(obj)

    if (typeof valueStore[key] === 'undefined') {
        valueStore[key] = callback(obj)
    }

    memoizeByKey.map.set(obj, valueStore)

    return valueStore[key]
}


/* ------------------------------------------------------------------------------------------------------------------------------ is Deep Equal -+- */
export const isDeepEqual = (first, second) => {

    const type = typeof first

    // item is completely different
    if (type !== typeof second)
        return false

    // handle primitive types
    if (['undefined', 'boolean', 'number', 'string', 'bigint'].includes(type))
        return first === second

    // ignore complex types
    if (type !== 'object' && type !== 'array')
        return true

    // - handle arrays and objects

    // size does not match
    if (getSize(first) !== getSize(second))
        return false

    // sort array values before comparison
    if (type === 'array') {
        first.sort()
        second.sort()
    }

    // perform recursive comparison for arrays and objects
    for (const id in first) {
        if (!first.hasOwnProperty(id)) continue

        if (first[id] !== second[id]) {
            if (!isDeepEqual(first[id], second[id]))
                return false
        }
    }

    return true
}

/* ---------------------------------------------------------------------------------------------------------------------------------- render If -+- */
export function renderIf(condition, component) {
    return condition ? (isFunction(component) ? component() : component) : null
}

/* -------------------------------------------------------------------------------------------------------------------------------- is Function -+- */
export function isFunction(input) {
    return typeof input === 'function'
}

/* ------------------------------------------------------------------------------------------------------------------------ create Query String -+- */
export function createQueryString(data) {
    const ret = []

    for (let d in data) {
        if (data.hasOwnProperty(d))
            ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]))
    }

    return ret.join('&')
}

/* ----------------------------------------------------------------------------------------------------------------------------------- get Size -+- */
export function getSize(obj) {
    let size = 0, key
    for (key in obj) {
        if (obj.hasOwnProperty(key)) size++
    }

    return size
}

/* ------------------------------------------------------------------------------------------------------------------------------ use Proxy Ref -+- */
/**
 * @see https://stackoverflow.com/a/64559005/4306828
 * @template T
 * @param {T} initialValue
 * @return {T}
 */
export function useProxyRef(initialValue) {
    const value = useRef(initialValue)

    return new Proxy(value, {
        get(target, key) {
            return target.current[key]
        },
        set(target, key, value) {
            return target.current[key] = value
        },
    })
}

/* ----------------------------------------------------------------------------------------------------------------------------------- is Empty -+- */
export function isEmpty(obj) {
    return getSize(obj) === 0
}

/* --------------------------------------------------------------------------------------------------------------------------------- get Cookie -+- */
export function getCookie(name) {
    let value = '; ' + document.cookie
    let parts = value.split('; ' + name + '=')
    if (parts.length === 2) return parts.pop().split(';').shift()
}

/* --------------------------------------------------------------------------------------------------------------------------------- set Cookie -+- */
export function setCookie(name, value, exdays) {
    let d = new Date()
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000))
    let expires     = 'expires=' + d.toUTCString()
    document.cookie = name + '=' + value + ';' + expires + ';path=/'
}

/* ------------------------------------------------------------------------------------------------------------------------------------ hash 53 -+- */
/**
 * Simple non-cryptographic string hash function.
 *
 * @source https://stackoverflow.com/a/52171480/4306828
 * @param str
 * @param seed
 * @returns {string}
 */
export function hash53(str, seed = 0) {
    let h1 = 0xdeadbeef ^ seed, h2 = 0x41c6ce57 ^ seed
    for (let i = 0, ch; i < str.length; i++) {
        ch = str.charCodeAt(i)
        h1 = Math.imul(h1 ^ ch, 2654435761)
        h2 = Math.imul(h2 ^ ch, 1597334677)
    }
    h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^ Math.imul(h2 ^ (h2 >>> 13), 3266489909)
    h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^ Math.imul(h1 ^ (h1 >>> 13), 3266489909)
    return String(4294967296 * (2097151 & h2) + (h1 >>> 0))
}

/* ------------------------------------------------------------------------------------------------------------------------------ delete Cookie -+- */
export function deleteCookie(name) {
    document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;'
}

/* ---------------------------------------------------------------------------------------------------------------------------------- Log Fancy -+- */
export class LogFancy {
    static message      = ''
    static style        = {}
    static disabledTags = []
    // If set, this overrides `disabledTags`.
    static enabledTags  = []
    static currentTag   = 'default'
    static printTags    = false

    static tag(tag) {
        this.currentTag = tag

        return this
    }

    static setStyle(rule, value, message) {
        this.style[rule] = value

        // If a message was provided, no further styles are expected. Therefore log the message and terminate.
        if (message) return this.print(message)

        return this
    }

    static big(message) {
        this.setStyle('font-size', '20px')
        return this.setStyle('font-weight', 'bold', message)
    }

    static red(message) {
        return this.setStyle('color', 'darkred', message)
    }

    static orange(message) {
        return this.setStyle('color', 'orange', message)
    }

    static blue(message) {
        return this.setStyle('color', 'cornflowerblue', message)
    }

    static purple(message) {
        return this.setStyle('color', 'mediumpurple', message)
    }

    static print(message) {
        const tag       = this.currentTag
        this.currentTag = 'default'

        // Tag whitelist always override tag blacklist.
        if (this.enabledTags.length) {
            if (!this.enabledTags.includes(tag)) return false
        } else if (this.disabledTags.includes(tag)) return false

        let styleString = ''
        for (const rule in this.style) {
            styleString += (rule + ': ' + this.style[rule] + '; ')
        }

        console.log('%c' + message, styleString)
        if (this.printTags) console.log(tag)

        this.message = ''
        this.style   = {}
    }
}

/* ----------------------------------------------------------------------------------------------------------------------- create Async Request -+- */
/**
 * A wrapper for `createAsyncThunk`.
 *
 * @param settings
 * @returns {{request: (((arg: void) => (null: GetDispatch<{}>, null: () => GetState<{}>, null: GetExtra<{}>) => (Promise<PayloadAction<any, string, {arg: void; requestId: string}, never> | PayloadAction<GetRejectValue<{}> | any, string, {arg: void; requestId: string; aborted: boolean}, SerializedError>> & {abort: ((null?: (string | any)) => void)})) & {pending: ActionCreatorWithPreparedPayload<[string , void], any, string, never, {arg: void; requestId: string}>; rejected: ActionCreatorWithPreparedPayload; GetRejectValue: (()); undefined; string; SerializedError; arg: void; requestId: string; aborted: boolean}), actions: {}, initialState: {}}}
 */
export function createAsyncRequest(settings) {

    settings = Object.assign({}, {
        // slice used
        slice: 'items',
        // name of the request
        name: 'itemRequest',
        // `createAsyncThunk` callback
        callback: undefined,
        // override some of the initial state
        initialState: {},
        // state callbacks [optional]
        pending: undefined,
        fulfilled: undefined,
        rejected: undefined,
    }, settings)

    // this will be injected into the state
    const initialState = {
        [settings.name]: {
            isFetching: false,
            currentRequestId: undefined,
            error: false,
            ...settings.initialState,
        },
    }

    const request = createAsyncThunk(
        settings.slice + '/' + settings.name,
        (args, thunkApi) => {

            const {getState, requestId}          = thunkApi
            const {isFetching, currentRequestId} = getState()[settings.slice][settings.name]
            if (!isFetching || requestId !== currentRequestId)
                return

            return settings.callback(args, thunkApi)
        },
    )

    const actions = {
        [request.pending]: (state, action) => {
            // make sure we run only one request at a time
            if (state[settings.name].isFetching) return

            // process pending state
            state[settings.name].error            = false
            state[settings.name].isFetching       = true
            state[settings.name].currentRequestId = action.meta.requestId

            // call user function, is required
            if (typeof settings.pending === 'function')
                settings.pending(state, action)
        },
        [request.fulfilled]: (state, action) => {
            // make sure we run only one request at a time
            const {requestId} = action.meta
            if (!state[settings.name].isFetching || state[settings.name].currentRequestId !== requestId) return

            // process pending fulfilled result
            state[settings.name].isFetching       = false
            state[settings.name].currentRequestId = undefined

            // call user function, is required
            if (typeof settings.fulfilled === 'function')
                settings.fulfilled(state, action)
        },
        [request.rejected]: (state, action) => {
            // make sure we run only one request at a time
            const {requestId} = action.meta
            if (!state[settings.name].isFetching || state[settings.name].currentRequestId !== requestId) return

            // process pending rejected result
            state[settings.name].isFetching       = false
            state[settings.name].currentRequestId = undefined
            state[settings.name].error            = action.payload

            // call user function, is required
            if (typeof settings.rejected === 'function')
                settings.rejected(state, action)
        },
    }

    return {
        request,
        actions,
        initialState,
    }
}

/* -------------------------------------------------------------------------------------------------------------------------------------- Cache -+- */
export class Cache {
    static #cacheStore  = {}
    static #tagStore    = {}
    static #currentTags = null

    static tags(tags) {
        if (typeof tags === 'string') tags = [tags]

        // Filter out falsy values for tags.
        this.#currentTags = tags.filter(tag => tag)

        return this
    }

    static put(key, value) {
        this.#cacheStore[key] = value

        // Register the tags as well. This will grant us invalidation with tag capabilities.
        if (this.#currentTags !== null) {
            for (const tag of this.#currentTags) {
                if (this.#tagStore[tag] === undefined) this.#tagStore[tag] = []

                this.#tagStore[tag].push(key)
            }

            this.#currentTags = null
        }
    }

    static get(key) {
        return this.#cacheStore[key]
    }

    static forget(key) {
        delete this.#cacheStore[key]
    }

    static flush() {
        // Flush the cache with given tags.
        if (this.#currentTags !== null) {
            for (const tag of this.#currentTags) {

                if (!this.#tagStore[tag]) continue

                // Delete all keys assigned to this tag.
                for (const key of this.#tagStore[tag]) {
                    delete this.#cacheStore[key]
                }

                // All keys assigned to this tag has been flushed.
                // We don't need to keep this tag any longer.
                delete this.#tagStore[tag]
            }

        } // No tags were given. Flush all items from the cache.
        else {
            this.#cacheStore = {}
            this.#tagStore   = {}
        }

        this.#currentTags = null
    }

    static remember(key, callback) {
        const cached = this.get(key)
        if (cached !== undefined) {
            this.#currentTags = null

            return cached
        }

        this.put(key, callback())
        this.#currentTags = null

        return this.get(key)
    }
}
