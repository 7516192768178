import { connect } from 'react-redux'

import node, { selectAllLinks } from '../redux/modules/node'
import ListComponent from './ListComponent'
import { hash53, LogFancy } from '../libraries/Helpers'
import { v4 as uuidv4 } from 'uuid'
import UiNode from '../libraries/UiNode'
import { store } from '../index'


/* ---------------------------------------------------------------------------------------------------------------------- map Dispatch To Props -+- */
function mapDispatchToProps(dispatch) {

    const actions = {
        /* ---------------------------------------------------------------------------------------------------------------- toggle Sub Nodes -+- */
        /**
         * @param {UiNode} uiNode
         */
        toggleSubNodes: uiNode => {
            dispatch(node.actions.toggleSubNodes({
                linkId: uiNode.linkId,
            }))
        },
        /* ------------------------------------------------------------------------------------------------------------------- toggle Completed -+- */
        /**
         * @param {UiNode} uiNode
         */
        toggleCompleted: uiNode => {
            dispatch(node.actions.toggleCompleted({
                nodeId: uiNode.nodeId,
            }))
        },
        /* ----------------------------------------------------------------------------------------------------------------- select Prev Node -+- */
        /**
         * @param {UiNode} uiNode
         */
        selectPrevNode: uiNode => {
            let prevSibling = uiNode.prevSibling()

            if (prevSibling.exists()) {
                let target = prevSibling

                while (target.lastChild().exists()) {
                    target = target.lastChild()
                }

                target.focus()
            } else if (uiNode.parent().exists()) {
                uiNode.parent().focus()
            }
        },
        /* ----------------------------------------------------------------------------------------------------------------- select Next Node -+- */
        selectNextNode: uiNode => {
            let firstChild = uiNode.firstChild()

            if (firstChild.exists()) {
                firstChild.focus()
                return true
            }

            let nextSibling = uiNode.nextSibling()
            if (nextSibling.exists()) {
                nextSibling.focus()
                return true
            }

            let node = uiNode

            while (node.parent().exists()) {
                node = node.parent()

                if (node.nextSibling().exists()) {
                    node.nextSibling().focus()
                    break
                }
            }
        },
        /* --------------------------------------------------------------------------------------------------------------------- move Node Up -+- */
        /**
         * @param {UiNode} uiNode
         */
        moveNodeUp: uiNode => {
            LogFancy.tag('nodeMove').big().orange('moveNodeUp')

            if (uiNode.prevSibling().exists()) {
                uiNode.moveToPos(uiNode.getIndex() - 1)

            } else {
                // This is already the first child. Try to add this as the last child of "parent.prevSibling".
                let newParent = uiNode.parent().prevSibling()

                if (newParent.exists()) {
                    // Generate new hash and recapture after appending to the parent.
                    const newHash = uiNode.addToParent(newParent, -1)

                    dispatch(node.actions.nodeFocus({
                        hash: newHash
                    }))

                    if (!newParent.getAttributes().is_extended)
                        newParent.toggleSubNodes()
                }
            }
        },
        /* ------------------------------------------------------------------------------------------------------------------- move Node Down -+- */
        /**
         * @param {UiNode} uiNode
         */
        moveNodeDown: uiNode => {
            LogFancy.tag('nodeMove').big().orange('moveNodeDown')

            if (uiNode.nextSibling().exists()) {
                uiNode.moveToPos(uiNode.getIndex() + 2)
            } else {
                // This is already the last child. Try to add this as the first child of "parent.nextSibling".
                let newParent = uiNode.parent().nextSibling()

                if (newParent.exists()) {
                    const newHash = uiNode.addToParent(newParent, 0)

                    dispatch(node.actions.nodeFocus({
                        hash: newHash
                    }))

                    if (!newParent.getAttributes().is_extended)
                        newParent.toggleSubNodes()
                }
            }
        },
        /* ---------------------------------------------------------------------------------------------------------------------- delete Node -+- */
        /**
         * @param {UiNode} uiNode
         */
        deleteNode: uiNode => {
            actions.selectPrevNode(uiNode)

            // delete the node and the link
            dispatch(node.actions.linkDelete({id: uiNode.linkId}))
            dispatch(node.actions.nodeDelete({id: uiNode.nodeId}))
        },
        /* ---------------------------------------------------------------------------------------------------------------------- create Node -+- */
        /**
         * @param {UiNode} uiNode
         */
        createNode: uiNode => {
            // Generate new uuid for the node and the link.
            const nodeId = uuidv4()
            const linkId = uuidv4()

            let parent, order = 0

            if (uiNode.getAttributes().is_extended && uiNode.children().length) {
                // Add the new node as a child to this one.
                parent = uiNode
                order  = UiNode.getOrderForPos(uiNode.children(), 0)
            } else {
                // Add the new node as a sibling to this one.
                parent = uiNode.parent()
                order  = UiNode.getOrderForPos(uiNode.siblings(), uiNode.getIndex() + 1)
            }

            // Create the node.
            dispatch(node.actions.nodeCreate({
                id: nodeId,
                attributes: {
                    type: 'basic',
                    title: '',
                },
            }))

            const newHash = hash53(parent.instanceHash + nodeId)

            // Create the link.
            dispatch(node.actions.linkCreate({
                id: linkId,
                attributes: {
                    parent_id: parent.nodeId,
                    child_id: nodeId,
                    order: order,
                },
            }))

            dispatch(node.actions.nodeFocus({
                hash: newHash
            }))
        },

        /* ---------------------------------------------------------------------------------------------------------------------- indent Node -+- */
        /**
         * @param {UiNode} uiNode
         */
        indentNode: uiNode => {
            // Move this node under its previous sibling.

            const newParent = uiNode.prevSibling()

            if (newParent && newParent.exists()) {

                const newHash = uiNode.addToParent(newParent, -1)

                if (!newParent.getAttributes().is_extended)
                    newParent.toggleSubNodes()

                dispatch(node.actions.nodeFocus({
                    hash: newHash
                }))
            }
        },

        /* --------------------------------------------------------------------------------------------------------------------- outdent Node -+- */
        /**
         * @param {UiNode} uiNode
         */
        outdentNode: uiNode => {
            // Move this node after its parent.
            let parent = uiNode.parent()

            if (!parent.exists()) return false

            const newHash = uiNode.insertAfter(parent)

            dispatch(node.actions.nodeFocus({
                hash: newHash
            }))
        },

        /* ---------------------------------------------------------------------------------------------------------------------------- zoom In -+- */
        /**
         * @param {UiNode} uiNode
         */
        zoomIn: uiNode => {

            // If node does not have any children, zoom into the parent instead.
            if (!uiNode.hasChildren()) uiNode = uiNode.parent()

            const firstChild = uiNode.firstChild()

            // After zooming in, focus to the first child if there is one.
            dispatch(node.actions.nodeFocus({
                hash: hash53(firstChild.nodeId),
            }))

            dispatch(node.actions.setViewRoot({
                nodeId: uiNode.nodeId,
            }))
        },
        /* --------------------------------------------------------------------------------------------------------------------------- zoom Out -+- */
        zoomOut: uiNode => {
            // Zoom out completely.
            const parentId = uiNode.getAttributes().parent_id

            if (parentId) {
                dispatch(node.actions.setViewRoot({
                    nodeId: false,
                }))
            }
        },
        /* ----------------------------------------------------------------------------------------------------------------------- add To Right -+- */
        addToRight: uiNode => {
            const state           = store.getState()
            const rightRootNodeId = state.node.rightRootNodeId

            if (!rightRootNodeId) {
                // set this as right root
                dispatch(node.actions.setRightRoot({
                    nodeId: uiNode.nodeId,
                }))

                const firstChild = uiNode.firstChild()

                if (firstChild.exists()) {
                    // After zooming in, focus to the first child if there is one.
                    dispatch(node.actions.nodeFocus({
                        hash: hash53(hash53(uiNode.nodeId) + firstChild.nodeId),
                    }))
                }

            } else if (rightRootNodeId !== uiNode.rootNodeId) {
                // Get the right root node.
                const rightRootNodeElement = document.querySelector(`.ygg-node.ygg-root[data-node-id="${rightRootNodeId}"]`)

                const rightRootNode = new UiNode(rightRootNodeElement, false)

                uiNode.addToParent(rightRootNode, -1, true)
            }
        },
        /* ------------------------------------------------------------------------------------------------------------------ remove From Right -+- */
        /**
         * @param {UiNode} uiNode
         */
        removeFromRight: uiNode => {
            const state           = store.getState()
            const rightRootNodeId = state.node.rightRootNodeId

            if (rightRootNodeId === uiNode.rootNodeId) {
                // This node is under right root, we can remove the link to detach it from the right.
                dispatch(node.actions.linkDelete({
                    id: uiNode.linkId,
                }))
            } else {
                // This item is not nested into right root, we remove the right root.
                dispatch(node.actions.removeRightRoot(uiNode))
            }
        },
        /* -------------------------------------------------------------------------------------------------------------------- node Set Type -+- */
        nodeSetType: uiNode => {

            // toggle node type between 'basic' and 'title'
            dispatch(node.actions.nodeUpdate({
                id: uiNode.nodeId,
                attributes: {type: uiNode.getAttributes().type === 'basic' ? 'title' : 'basic'},
            }))

        },
        /* -------------------------------------------------------------------------------------------------------------------- focus To Node -+- */
        focusToNode: (hash) => {
            dispatch(node.actions.nodeFocus({
                hash: hash,
            }))
        },
        /* -------------------------------------------------------------------------------------------------------------- toggle Show Completed -+- */
        toggleShowCompleted: () => {
            dispatch(node.actions.toggleShowCompleted())
        },
    }

    return actions
}

/* ---------------------------------------------------------------------------------------------------------------------- map Dispatch To Props -^- */


/* ------------------------------------------------------------------------------------------------------------------------- map State To Props -+- */
function mapStateToProps(state, props) {
    LogFancy.tag('mapStateToProps').print('List.mapStateToProps')

    const viewRootId = state.node.viewRootId || props.rootNodeId

    return {
        links: selectAllLinks(state).filter(link => link.attributes.parent_id === viewRootId),
        node: state.node.nodes[viewRootId],
        showCompleted: state.node.showCompleted,
        rootId: props.rootId,
        viewRootId: viewRootId,
        activeNodeHash: state.node.activeNodeHash,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ListComponent)
