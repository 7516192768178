import auth from '../redux/modules/auth'
import AppComponent from './AppComponent'
import { fetchInitialData } from '../redux/modules/nodeRequests'
import { LogFancy, reduxConnectPipeline } from '../libraries/Helpers'

const mapDispatchToProps = dispatch => {
    return {
        logout: () => {
            dispatch(auth.actions.logout())
        },
        fetchInitialData: () => {
            dispatch(fetchInitialData())
        },
    }
}

const mapStateToProps = (state) => {
    LogFancy.tag('mapStateToProps').print('App.mapStateToProps')
    return {
        auth: state.auth,
        syncStatus: state.syncBuffer.status,
    }
}

export default reduxConnectPipeline(
    AppComponent,
    // areStatesEqual()
    (next, prev) => (
        next.auth === prev.auth &&
        next.syncBuffer.status === prev.syncBuffer.status
    ),
    mapStateToProps,
    mapDispatchToProps,
)
