import React from 'react'
import classNames from 'classnames'
import Node from './Node'
import { hash53, LogFancy } from '../libraries/Helpers'

export default function NodeComponent(props) {

    function renderChildren() {
        if (!props.link || !props.link.is_extended || !props.childLinks) return null

        return props.childLinks.map(link =>
            (<Node
                key={link.id}
                id={link.attributes.child_id}
                linkId={link.id}
                rootNodeId={props.rootNodeId}
                hash={hash53(props.hash + link.attributes.child_id)}
            />))
    }

    let nodeClasses = classNames('ygg-node', {
        completed: !!props.node.completed_at,
        extended: props.link && props.link.is_extended,
        twig: !props.childLinks || !props.childLinks.length,
        'node-type-title': props.node.type === 'title',
    })

    LogFancy.tag('render').big().red().print('NodeComponent.render()')

    return (
        <div className={nodeClasses}
             data-node-id={props.id}
             data-link-id={props.linkId}
             data-hash={props.hash}
        >
            <div className="attributes">
                <div className="title-group w100">
                    <span className="bullet"/>

                    <span className="title-input form-control ygg-title"
                          suppressContentEditableWarning={true}
                          contentEditable={true}
                          tabIndex={0}
                          onInput={e => props.inputChange(props.id, 'title', e.target.innerHTML, props.hash, document.getSelection().focusOffset)}
                          dangerouslySetInnerHTML={{__html: props.node.title}}
                    ></span>
                </div>
            </div>

            <div className="ygg-sub-nodes">
                {renderChildren()}
            </div>
        </div>
    )
}
