import { createSlice } from '@reduxjs/toolkit'
import memoize from 'proxy-memoize'

/* ------------------------------------------------------------------------------------------------------------------------------------------------ */
/* -------------------------------------------------------------------------------------------------------------------------------------- SLICE -+- */
/* ------------------------------------------------------------------------------------------------------------------------------------------------ */
const initialState = {
    isActive: false,
    query: '',
}

const action = createSlice({
    name: 'action',
    initialState: initialState,
    reducers: {
        show: state => {
            state.isActive = true
        },
        hide: state => {
            state.isActive = false
        },
        queryChange: (state, action) => {
            state.query = action.payload
        },
    },
})

export default action

export const actionModalShow = action.actions.show
export const actionModalHide = action.actions.hide

const getQuery = state => state.action.query

export const selectFilteredNodes = memoize(state => {
        if (!state.action.query) return []
        let numMatches = 0
        return Object.values(state.node.nodes).filter(node => {
            if (numMatches >= 20) return false
            if (!node.attributes || !node.attributes.title) return false

            const index = node.attributes.title.toUpperCase().indexOf(state.action.query.toUpperCase())

            if (index !== -1) {
                numMatches++
                return true
            }
            return false
        })
    },
)
