import { createSlice } from '@reduxjs/toolkit'
import { store } from '../../index'
import { consoleHr, isEmpty, mergeDeep } from '../../libraries/Helpers'
import ApiClient from '../../libraries/ApiClient'

/* -------------------------------------------------------------------------------------------------------------------------------------- slice -+- */
const initialState = {
    syncBuffer: {},
    status    : 'ready',
    errors    : [],
}
let lastActionTime = Date.now()

const syncBuffer = createSlice({
    name        : 'syncBuffer',
    initialState: initialState,
    reducers    : {
        setStatus      : (state, action) => {
            const status   = action.payload.status
            const response = action.payload.response
            
            if (status === 'error')
                state.errors.push(response)
            
            state.status = status
        },
        addToSyncBuffer: (state, action) => {
            const identifier = action.payload.identifier
            const payload    = action.payload.payload
            
            lastActionTime = Date.now()
            
            
            // add the action to the buffer, or merge with existing identical action found in the buffer
            state.syncBuffer[identifier] = mergeDeep({}, state.syncBuffer[identifier], payload)
        },
        flushSyncBuffer: (state, action) => {
            state.syncBuffer = {}
        },
    },
})


setInterval(async () => {
    if (lastActionTime > Date.now() - 2000) return false
    
    lastActionTime = Date.now()
    
    let state  = store.getState()
    let buffer = state.syncBuffer.syncBuffer
    if (!isEmpty(buffer)) {
        store.dispatch(syncBuffer.actions.setStatus({
            'status': 'progress',
        }))
        
        let response = await ApiClient.postRequest('sync', {
            changes: Object.values(buffer),
        })
        
        let error = false
        
        if (response.status !== 200) {
            error = 'BAD_HTTP_RESPONSE_CODE: ' + response.status
        } else if (!response.data.result) {
            error = 'SERVER_RETURNED_FALSE_FOR_SYNC_SUCCESS'
        }
        
        if (error) {
            console.group(consoleHr('sync FAILED!'))
            console.error('ATTENTION! SYNC FAILED!')
            console.log('REASON:', error)
            console.dir('response:', response)
            console.groupEnd()
            
            store.dispatch(syncBuffer.actions.setStatus({
                status  : 'error',
                response: response,
            }))
        } else {
            console.groupCollapsed(consoleHr('sync success'))
            console.dir('response:', response)
            console.groupEnd()
            
            store.dispatch(syncBuffer.actions.setStatus({
                status: 'ok',
            }))
        }
        
        store.dispatch(syncBuffer.actions.flushSyncBuffer())
    }
}, 200)


export default syncBuffer
