import React from 'react'
import Spinner from './_utils/Spinner'
import App from './App'
import Login from './Login'
import { LogFancy } from '../libraries/Helpers'


function SplashComponent(props) {
    
    LogFancy.tag('render').red('SplashComponent.render()')
    
    if (props.auth.isFetching) {
        return (<Spinner size="10x"/>)
    } else if (!props.auth.isLoggedIn) {
        return (<Login/>)
    } else {
        return (<App/>)
    }
}

export default SplashComponent
