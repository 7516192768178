import { sessionRestore } from '../redux/modules/auth'
import SplashComponent from './SplashComponent'
import { LogFancy, reduxConnectPipeline } from '../libraries/Helpers'

const mapDispatchToProps = dispatch => {
    return {
        beforeLoad: () => {
            // attempt to restore any previous session
            dispatch(sessionRestore())
        },
    }
}

const mapStateToProps = (state) => {
    LogFancy.tag('mapStateToProps').print('Splash.mapStateToProps')
    
    return {
        auth: state.auth,
    }
}

export default reduxConnectPipeline(
    SplashComponent,
    // areStatesEqual()
    (next, prev) => next.auth === prev.auth,
    mapStateToProps,
    mapDispatchToProps,
)
