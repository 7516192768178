import React, { useEffect } from 'react'
import Spinner from './_utils/Spinner'
import { isDeepEqual, LogFancy } from '../libraries/Helpers'
import classNames from 'classnames'
import Action from './Action'
import List from './List'
import Node from './Node'


function TreeComponent(props) {

    let shiftPressed = false
    let shiftTimeout

    function handleCaptureKeyup(e) {
        let keyCode = e.keyCode || e.which

        switch (keyCode) {
            // [Shift] key
            case 16:
                // [Shift] was pressed twice in a short time.
                if (shiftPressed && !props.isActionModalActive) {
                    // Prevent event capture from propagating down to child elements.
                    e.stopPropagation()
                    props.actionModalShow()
                }

                clearTimeout(shiftTimeout)
                shiftTimeout = setTimeout(() => {
                    shiftPressed = false
                }, 200)

                shiftPressed = true
                break
            // [Esc] key
            case 27:
                if (props.isActionModalActive) {
                    // Prevent event capture to propagate down to child elements.
                    e.stopPropagation()
                    props.actionModalHide()
                }
                break
            // skip default
        }
    }

    useEffect(() => {
        document.addEventListener('keyup', handleCaptureKeyup, true)

        return () => document.removeEventListener('keyup', handleCaptureKeyup, true)
    })

    LogFancy.tag('render').red('TreeComponent.render()')

    if (props.isFetching)
        return <Spinner size="10x"/>

    let containerClasses = classNames({
        'col-md-6': props.rightRootNodeId,
        'col-md-12': !props.rightRootNodeId,
    })

    return (
        <div className="container-fluid">
            <div className="row">

                <div className={containerClasses} style={{maxHeight: 'calc(100vh - 176px)', overflowY: 'auto'}}>
                    <List
                        rootId={props.rootId}
                        rootNodeId={props.rootNodeId}
                    />
                </div>

                {props.rightRootNodeId &&
                    <div className={containerClasses} style={{maxHeight: 'calc(100vh - 176px)', overflowY: 'auto'}}>
                        <List
                            rootId={props.rightRootId}
                            rootNodeId={props.rightRootNodeId}
                            showTitles={true}
                        />
                    </div>}

                {props.completedToday &&
                    <div className="ygg-node ygg-root mt-2 col-12" style={{maxHeight: '100px', overflowY: 'auto'}}>
                        <span className="pb-3">Completed Today</span>
                        <div className="ygg-sub-nodes">
                            {props.completedToday.map(node =>
                                <Node
                                    key={node.id}
                                    id={node.id}
                                    linkId={false}
                                    rootNodeId={false}
                                />,
                            )}
                        </div>
                    </div>}

            </div>

            <Action/>
        </div>
    )
}

function shouldUpdate(props, nextProps) {
    LogFancy.tag('shouldUpdate').blue('TreeComponent.shouldUpdate()')

    let isChanged = !isDeepEqual([
        props.isFetching,
        props.rootId,
        props.rightRootId,
        props.rightRootNodeId,
        props.completedToday,
        props.showCompleted,
        props.isActionModalActive,
    ], [
        nextProps.isFetching,
        nextProps.rootId,
        nextProps.rightRootId,
        nextProps.rightRootNodeId,
        nextProps.completedToday,
        nextProps.showCompleted,
        nextProps.isActionModalActive,
    ])

    if (isChanged) {
        console.log('TreeComponent.shouldComponentUpdate', isChanged)
    }

    return !isChanged
}

export default React.memo(TreeComponent, shouldUpdate)
