import React from 'react'
import { render } from 'react-dom'
import thunk from 'redux-thunk'
import { applyMiddleware, createStore } from 'redux'
import { Provider } from 'react-redux'
import Splash from './components/Splash'
import { sessionRestore } from './redux/modules/auth'

import { composeWithDevTools } from 'redux-devtools-extension'
import reducer from './redux'
import { initWatchers } from './redux/watchers'
import { makeFunctionWithBuffer } from './libraries/Helpers'
import { batchedSubscribe } from 'redux-batched-subscribe'

// LogFancy.enabledTags = ['nodeMove', 'active']

const bufferNotify = makeFunctionWithBuffer(notify => notify(), 10)

export const store = createStore(
    reducer,
    composeWithDevTools(
        applyMiddleware(thunk),
        batchedSubscribe(bufferNotify),
    ),
)

initWatchers()

store.dispatch(sessionRestore())


render(
    <Provider store={store}>
        <Splash/>
    </Provider>,
    document.getElementById('root'),
)
