import { createSlice } from '@reduxjs/toolkit'
import ApiClient from '../../libraries/ApiClient'
import { createAsyncRequest, deleteCookie, getCookie, setCookie } from '../../libraries/Helpers'


/* ------------------------------------------------------------------------------------------------------------------------------ login Request -+- */


const loginRequest = createAsyncRequest({
    slice    : 'auth',
    name     : 'loginRequest',
    callback : async ({email, password}, thunkApi) => {
        const response = await ApiClient.postRequest('login', {
            email   : email,
            password: password,
        })
        
        // console.info('login response:', response)
        
        if (response.status === 200) {
            return response.data
        } else {
            return thunkApi.rejectWithValue(response.data)
        }
        
    },
    fulfilled: (state, action) => {
        setCookie('auth', JSON.stringify({
            api_token: action.payload.api_token,
            id       : action.payload.user.id,
            email    : action.payload.user.email,
            name     : action.payload.user.name,
            surname  : action.payload.user.surname,
        }), 10)
        
        state.isLoggedIn = true
        state.api_token  = action.payload.api_token
        state.user       = {
            id     : action.payload.user.id,
            email  : action.payload.user.email,
            name   : action.payload.user.name,
            surname: action.payload.user.surname,
        }
    },
})

export const {request: login} = loginRequest


/* -------------------------------------------------------------------------------------------------------------------- session Restore Request -+- */

const sessionRestoreRequest = createAsyncRequest({
    slice    : 'auth',
    name     : 'sessionRestoreRequest',
    callback : async (args, thunkApi) => {
        
        // Load auth info from cookies. Continue any existing session.
        // Ideally this will run every time app starts.
        // console.info('Attempting to restore the session from cookies')
        let userData = getCookie('auth')
        
        if (userData) {
            // successfully restored previous session
            userData = JSON.parse(userData)
            // console.info('auth data from cookies:', userData)
            // console.info('Session was successfully restored.')
            
            return userData
        } else {
            return thunkApi.rejectWithValue([])
        }
    },
    fulfilled: (state, action) => {
        // successfully restored the user session from cookies
        state.isLoggedIn      = true
        state.sessionRestored = true
        state.api_token       = action.payload.api_token
        state.user            = {
            id     : action.payload.id,
            email  : action.payload.email,
            name   : action.payload.name,
            surname: action.payload.surname,
        }
    },
    rejected : (state, action) => {
        state.sessionRestored = true
    },
})

export const {request: sessionRestore} = sessionRestoreRequest


/* -------------------------------------------------------------------------------------------------------------------------------------- slice -+- */

const initialState = {
    sessionRestored: false,
    isLoggedIn     : false,
    api_token      : null,
    user           : {
        id     : null,
        email  : null,
        name   : null,
        surname: null,
    },
    ...loginRequest.initialState,
    ...sessionRestoreRequest.initialState,
}

const auth = createSlice({
    name         : 'auth',
    initialState : initialState,
    reducers     : {
        logout: (state) => {
            console.info('Removing saved session from cookies.')
            deleteCookie('auth')
            return {
                ...initialState,
                isFetching: false,
            }
        },
    },
    extraReducers: {
        ...loginRequest.actions,
        ...sessionRestoreRequest.actions,
    },
})

export default auth
