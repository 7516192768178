import { createSlice } from '@reduxjs/toolkit'
import { initialDataRequest } from './nodeRequests'
import memoize from 'proxy-memoize'
import moment from 'moment'
import { NodeNotifier } from '../../components/Node'

/* ------------------------------------------------------------------------------------------------------------------------------------------------ */
/* -------------------------------------------------------------------------------------------------------------------------------------- SLICE -+- */
/* ------------------------------------------------------------------------------------------------------------------------------------------------ */
const initialState = {
    error: '',
    initialDataLoaded: false,
    rootId: null,
    viewRootId: null,
    rootNodeId: null,
    rightRootId: null,
    rightRootNodeId: null,
    activeNodeHash: null,
    showCompleted: true,
    nodes: {},
    links: {},
    ...initialDataRequest.initialState,
}

const node = createSlice({
    name: 'node',
    initialState: initialState,
    reducers: {
        setRightRoot: (state, action) => {
            state.rightRootNodeId = action.payload.nodeId
        },

        removeRightRoot: (state, action) => {
            state.rightRootNodeId = null
        },

        toggleShowCompleted: (state, action) => {
            state.showCompleted = !state.showCompleted
        },

        /* -------------------------------------------------------------------------------------------------------------------- node Reducers <-- */
        nodeUpdate: (state, action) => {
            const id         = action.payload.id
            const attributes = action.payload.attributes

            NodeNotifier.nodeUpdated(id)

            state.nodes[id].attributes = {...state.nodes[id].attributes, ...attributes}
        },

        nodeCreate: (state, action) => {
            const attributes = action.payload.attributes

            state.nodes[action.payload.id] = {
                type: 'items',
                id: action.payload.id,
                attributes: attributes,
            }
        },

        nodeDelete: (state, action) => {
            delete state.nodes[action.payload.id]
        },

        nodeFocus: (state, action) => {
            // record active node
            state.activeNodeHash = action.payload.hash
        },

        setViewRoot: (state, action) => {
            state.viewRootId = action.payload.nodeId
        },

        /* ---------------------------------------------------------------------------------------------------------------------- link Reducers <-- */
        linkUpdate: (state, action) => {
            const id             = action.payload.id
            const attributes     = action.payload.attributes
            const prevAttributes = state.links[id].attributes

            // Mark the link for re-render.
            NodeNotifier.linkUpdated(id)

            // If the item order was changed, mark the parent for re-render.
            if (attributes.order !== undefined) NodeNotifier.nodeUpdated(prevAttributes.parent_id)

            // If parent was changed, mark both the old and the new parents for re-render.
            if (attributes.parent_id !== undefined) {
                NodeNotifier.nodeUpdated(prevAttributes.parent_id)
                NodeNotifier.nodeUpdated(attributes.parent_id)
            }

            state.links[id].attributes = {...prevAttributes, ...attributes}
        },

        linkCreate: (state, action) => {
            const id         = action.payload.id
            const attributes = action.payload.attributes

            NodeNotifier.nodeUpdated(attributes.parent_id)

            state.links[action.payload.id] = {
                type: 'links',
                id: id,
                attributes: attributes,
            }
        },

        linkDelete: (state, action) => {
            const id             = action.payload.id
            const prevAttributes = state.links[id].attributes

            NodeNotifier.nodeUpdated(prevAttributes.parent_id)

            delete state.links[action.payload.id]
        },

        /* ------------------------------------------------------------------------------------------------------------------- complex Reducers <-- */
        moveLinkUp: (state, action) => {

        },

        toggleSubNodes(state, action) {
            const link = state.links[action.payload.linkId]

            node.caseReducers.linkUpdate(state, {
                payload: {
                    id: link.id,
                    attributes: {is_extended: !link.attributes.is_extended},
                },
            })
        },

        toggleCompleted(state, action) {
            const _node = state.nodes[action.payload.nodeId]

            node.caseReducers.nodeUpdate(state, {
                payload: {
                    id: _node.id,
                    attributes: {completed_at: _node.attributes.completed_at ? null : moment().format('YYYY-MM-DD HH:mm:ss')},
                },
            })
        },
        /* ---------------------------------------------------------------------------------------------------------------------------------------- */
    },
    extraReducers: {
        ...initialDataRequest.actions,
    },
})

export default node

/* ------------------------------------------------------------------------------------------------------------------------------------------------ */
/* ---------------------------------------------------------------------------------------------------------------------------------- SELECTORS -+- */
/* ------------------------------------------------------------------------------------------------------------------------------------------------ */

export const selectAllNodes = memoize(state => {
    return Object.values(state.node.nodes).filter(node => node && node.attributes)
})

export const selectAllLinks = memoize(state => {
    const completedNodeIds = getCompletedNodeIds(state.node.nodes)

    let links = Object.values(state.node.links)
        .filter(link => link && link.attributes)
        .sort((a, b) => Number(a.attributes.order) - Number(b.attributes.order))

    if (!state.node.showCompleted) {
        links = links.filter(link => completedNodeIds.includes(link.attributes.child_id))
    }

    return links
})

const getCompletedNodeIds = memoize(nodes => {
    let nodeIds = Object.values(nodes)
    nodeIds     = nodeIds.filter(node => node.attributes && !node.attributes.completed_at)

    return nodeIds.map(node => node.id)
})

export const selectCompleted = memoize(state => {
    const nodes = selectAllNodes(state)
    return nodes.filter(node => node.attributes && node.attributes.completed_at)
})

export const selectCompletedToday = memoize(state => {
        const nodes = selectAllNodes(state)

        const dayStart   = new Date()
        let dayStartHour = 5

        if (dayStart.getHours() < dayStartHour) dayStartHour -= 24

        dayStart.setHours(dayStartHour, 0, 0, 0)

        return nodes.filter(node => Date.parse(node.attributes.completed_at) > dayStart)
    },
)
