import { connect } from 'react-redux'
import Login from './LoginComponent'
import { login } from '../redux/modules/auth'

const mapDispatchToProps = (dispatch) => {
    return {
        attemptLogin: (email, password) => {
            dispatch(login({email, password}))
        },
    }
}


function mapStateToProps(state) {
    
    return {
        auth: {
            ...state.auth,
        },
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Login)
