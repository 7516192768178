import NodeComponent from './NodeComponent'
import node, { selectAllLinks, selectAllNodes } from '../redux/modules/node'
import { Cache, isDeepEqual, LogFancy, makeFunctionWithBuffer, reduxConnectPipeline } from '../libraries/Helpers'


/* ---------------------------------------------------------------------------------------------------------------------- map Dispatch To Props -+- */
const mapDispatchToProps = (dispatch) => {
    const dispatchWithBuffer = makeFunctionWithBuffer(dispatch, 2000)

    return {
        inputChange: (id, name, value, hash) => {
            dispatchWithBuffer(node.actions.nodeFocus({
                hash: hash
            }))
            dispatchWithBuffer(node.actions.nodeUpdate({
                id: id,
                attributes: {[name]: value},
            }))
        },
        /* ---------------------------------------------------------------------------------------------------------------------------------------- */
    }
}

/* --------------------------------------------------------------------------------------------------------------------------- are States Equal -+- */
function areStatesEqual(next, prev) {
    LogFancy.tag('areStatesEqual').big().purple('Node.areStatesEqual()')

    if (next.node.showCompleted !== prev.node.showCompleted) {
        NodeNotifier.resetAll()
    }

    return (
        next.node.showCompleted === prev.node.showCompleted &&
        next.node.nodes === prev.node.nodes &&
        next.node.links === prev.node.links
    )
}

export class NodeNotifier {
    static nodeUpdated(nodeId) {
        Cache.tags(nodeId).flush()
    }

    static linkUpdated(linkId) {
        Cache.tags(linkId).flush()
    }

    static resetAll() {
        Cache.tags('nodeProps').flush()
    }
}

/* ------------------------------------------------------------------------------------------------------------------------- map State To Props -+- */
function mapStateToProps(state, props) {
    const cacheKey = props.id + props.linkId + (props.showTitles ? 't-true' : 't-false')

    // Recalculate the props if there is no cached props for this component instance.
    return Cache.tags(['nodeProps', props.id, props.linkId]).remember(cacheKey, function () {
        LogFancy.big().orange('Recalculating node props')

        let titles = false

        // if (props.showTitles) {
        //     const links = selectAllLinks(state)
        //     const nodes = selectAllNodes(state)
        //
        //     // Get the first link.
        //     let link = links.find(link => link.attributes.child_id === props.id)
        //
        //     // Get the parent node.
        //     let parent = state.node.nodes[link.attributes.parent_id]
        //
        //     console.log(link, parent)
        // }

        return {
            node: state.node.nodes[props.id].attributes,
            titles: titles,
            link: !props.linkId ? null : state.node.links[props.linkId].attributes,
            isActive: state.node.activeNodeHash === props.hash,
            childLinks: selectAllLinks(state).filter(link => link.attributes.parent_id === props.id),
        }
    })
}


/* ------------------------------------------------------------------------------------------------------------------------------ should Update -+- */
function shouldUpdate(props, nextProps) {
    LogFancy.tag('shouldUpdate').blue('NodeComponent.shouldUpdate()')

    const isEqual = isDeepEqual([
        props.link,
        props.node,
        props.childLinks,
    ], [
        nextProps.link,
        nextProps.node,
        nextProps.childLinks,
    ])

    return isEqual
}

export default reduxConnectPipeline(
    NodeComponent,
    areStatesEqual,
    mapStateToProps,
    mapDispatchToProps,
    shouldUpdate,
)
