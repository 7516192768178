import { combineReducers } from 'redux'
import auth from './modules/auth'
import node from './modules/node'
import syncBuffer from './modules/syncBuffer'
import action from './modules/action'


const rootReducer = combineReducers({
    auth: auth.reducer,
    node: node.reducer,
    action: action.reducer,
    syncBuffer: syncBuffer.reducer,
})

export default rootReducer
