import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

const PrivateRoute = ({comp: Component, auth: {isLoggedIn}, ...rest}) => {
    
    if (!isLoggedIn) {
        return <Redirect to="/login"/>
    } else {
        return <Route
            {...rest}
            render={props => <Component {...props} />}
        />
    }
}

PrivateRoute.propTypes = {
    auth: PropTypes.object.isRequired,
}

function mapStateToProps(state) {
    return {
        auth: state.auth,
    }
}

export default connect(mapStateToProps)(PrivateRoute)
