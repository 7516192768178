import { consoleHr, createQueryString } from './Helpers'
import axios from 'axios'
import { store } from '../index'


export default class ApiClient {
    
    // root API url
    // development
    // static apiUrl = 'http://api.finalapp.test/'
    static apiUrl = 'https://api.finalapp.umurkaragoz.com/'
    
    /* ------------------------------------------------------------------------------------------------------------------------------ get Token -+- */
    static getToken = () => {
        let state = store.getState()
        
        return state.auth.isLoggedIn ? state.auth.api_token : false
    }
    
    
    /* ------------------------------------------------------------------------------------------------------------------------ prepare Headers -+- */
    /**
     *
     * @param headers {Object}
     * @returns {*}
     */
    static prepareHeaders = (headers) => {
        
        headers = Object.assign({}, {
            'Content-Type': 'application/json',
            'Accept'      : 'application/json',
        }, headers)
        
        let token = ApiClient.getToken()
        
        if (token)
            headers.Authorization = 'Bearer ' + token
        
        return headers
    }
    
    /* ---------------------------------------------------------------------------------------------------------------------------- get Request -+- */
    /**
     * Make a GET request to an API endpoint
     *
     * @param uri {String}
     * @param parameters {Object}
     * @param headers {Object}
     * @returns {Promise.<*>}
     */
    static getRequest = async (uri, parameters = {}, headers = {}) => {
        
        let parameterString = createQueryString(parameters)
        parameterString     = parameterString.length ? '?' + parameterString : ''
        
        headers = ApiClient.prepareHeaders(headers)
        
        console.groupCollapsed(consoleHr('GET request'))
        console.log('request uri:', uri + parameterString)
        console.log('request headers:', headers)
        console.groupEnd()
        
        return axios.get(ApiClient.apiUrl + uri + parameterString, {
            headers: headers,
        })
    }
    
    /* --------------------------------------------------------------------------------------------------------------------------- post Request -+- */
    /**
     * Make a POST request to an API endpoint
     *
     * @param uri {String}
     * @param data {Object}
     * @param headers {Object}
     * @returns {Promise.<*>}
     */
    static postRequest = async (uri, data, headers = {}) => {
        
        headers = await ApiClient.prepareHeaders(headers)
        
        if (data instanceof FormData) {
            headers['Content-Type'] = 'multipart/form-data'
        }
        
        // set up the request
        
        console.groupCollapsed(consoleHr('POST request'))
        console.log('uri     :', uri)
        console.log('headers :', headers)
        console.log('data    :', data)
        console.groupEnd()
        
        return axios.post(ApiClient.apiUrl + uri, data, {
            headers: headers,
        })
            .then(response => response)
            .catch(error => error.response)
        
    }
    
    /* -------------------------------------------------------------------------------------------------------------------------- patch Request -+- */
    /**
     * Make a PATCH request to an API endpoint
     *
     * @param uri {String}
     * @param data {Object}
     * @param headers {Object}
     * @returns {Promise.<*>}
     */
    static patchRequest = async (uri, data, headers = {}) => {
        
        headers = await ApiClient.prepareHeaders(headers)
        
        if (data instanceof FormData)
            headers['Content-Type'] = 'multipart/form-data'
        
        // set up the request
        return (fetch(ApiClient.apiUrl + uri, {
            method : 'PATCH',
            headers: headers,
            body   : JSON.stringify(data),
        }))
            .then(response => response.json())
        
    }
    
    /* ---------------------------------------------------------------------------------------------------------------------------- get Request -+- */
    /**
     * Make a DELETE request to an API endpoint
     *
     * @param uri {String}
     * @param parameters {Object}
     * @param headers {Object}
     * @returns {Promise.<*>}
     */
    static delRequest = async (uri, parameters = {}, headers = {}) => {
        
        let parameterString = createQueryString(parameters)
        parameterString     = parameterString.length ? '?' + parameterString : parameterString
        
        headers = this.prepareHeaders(headers)
        
        return (fetch(ApiClient.apiUrl + uri + parameterString, {
            method : 'DELETE',
            headers: headers,
        }))
            .then(response => response.json())
    }
    
}
