import React from 'react'
import { BrowserRouter as Router, Link, Redirect, Route, Switch } from 'react-router-dom'
import PrivateRoute from './_utils/PrivateRoute'
// - import components
import Login from './Login'
import Tree from './Tree'
import { LogFancy } from '../libraries/Helpers'

class AppComponent extends React.Component {

    componentDidMount() {
        this.props.fetchInitialData()
    }

    render() {

        LogFancy.tag('render').red('AppComponent.render()')
        const status = this.props.syncStatus

        return (
            <div className="app" style={{maxHeight: '100vh', overflow: 'hidden'}}>
                <div id="status-bar" className="no-print">
                    <span className="fa-stack">
                        {status !== 'progress' &&
                            <i id="synced" className="fa fa-cloud fa-2x fa-fw fa-stack-1x"></i>
                        }
                        {this.props.syncStatus === 'ok' &&
                            <i id="ok" className="fa fa-check fa-1x fa-fw fa-stack-1x"></i>
                        }
                        {this.props.syncStatus === 'progress' &&
                            <i id="syncing" className="fa fa-cloud-upload fa-2x fa-fw fa-stack-1x blinker "></i>
                        }
                        {this.props.syncStatus === 'error' &&
                            <i id="syncing" className="fa fa-exclamation text-danger fa-2x fa-fw fa-stack-1x blinker "></i>
                        }
                    </span>
                </div>
                <Router>
                    <div>
                        <ul>
                            {this.props.auth.isLoggedIn &&
                                <li>
                                    Welcome, {this.props.auth.user.name} <Link to="/login" onClick={this.props.logout}>Logout</Link>
                                </li>
                            }
                            {!this.props.auth.isLoggedIn &&
                                <li>
                                    <Link to="/login">Login</Link>
                                </li>
                            }
                            <li>
                                <Link to="/tree">Tree</Link>
                            </li>
                        </ul>

                        <Switch>
                            <PrivateRoute path="/tree" comp={Tree}/>
                            <Route path="/login" component={Login}/>
                            <Redirect to="/tree"/>
                        </Switch>
                    </div>
                </Router>
            </div>
        )
    }
}


export default AppComponent
