import { store } from '../index'
import node from '../redux/modules/node'
import { v4 as uuidv4 } from 'uuid'
import { arrayMove, hash53, LogFancy } from './Helpers'

/* ------------------------------------------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------------------ Ui Node -+- */
/* ------------------------------------------------------------------------------------------------------------------------------------------------ */
/**
 * @property {HTMLElement} node
 * @property {string} linkId
 * @property {string} instanceHash
 */
export default class UiNode {
    constructor(node, rootNodeId) {
        this.node = node

        if (this.node) {
            this.linkId       = String(node.getAttribute('data-link-id'))
            this.nodeId       = String(node.getAttribute('data-node-id'))
            this.instanceHash = String(node.getAttribute('data-hash'))
            this.rootNodeId   = rootNodeId
        }
    }

    node         = null
    linkId       = null
    nodeId       = null
    instanceHash = null
    rootNodeId   = null
    #attributes  = null
    #siblings    = null

    /* ------------------------------------------------------------------------------------------------------------------------------- new Node -+- */
    newNode(newNode) {
        if (newNode instanceof UiNode) return newNode

        return new UiNode(newNode)
    }

    /**
     * @returns {{order: number, is_extended: bool, child_id: string, parent_id: string, type: string, title: string, detail: string, completed_at: string}} obj
     */
    getAttributes() {
        if (!this.exists()) return {}

        const links = store.getState().node.links
        const nodes = store.getState().node.nodes

        const linkAttributes     = this.linkId ? Object.assign({}, links[this.linkId].attributes) : {}
        const nodeAttributes     = this.nodeId ? Object.assign({}, nodes[this.nodeId].attributes) : {}
        const combinedAttributes = {...linkAttributes, ...nodeAttributes}

        delete combinedAttributes['id']

        return combinedAttributes

        // TODO: Optimize using cache.
        // if (this.#attributes === null) {
        //     const links      = store.getState().node.links
        //     this.#attributes = this.linkId ? Object.assign({}, links[this.linkId].attributes) : {}
        // }
        //
        // return this.#attributes
    }

    /* --------------------------------------------------------------------------------------------------------------------------------- parent -+- */
    parent() {
        if (!this.exists()) return this.newNode()

        let parent = this.node.parentElement ? this.node.parentElement.parentElement : false

        return this.newNode(parent)
    }

    /* --------------------------------------------------------------------------------------------------------------------------- prev Sibling -+- */
    prevSibling() {
        if (!this.exists()) return this.newNode()

        return this.newNode(this.siblings()[this.getIndex() - 1])
    }

    /* --------------------------------------------------------------------------------------------------------------------------- next Sibling -+- */
    nextSibling() {
        if (!this.exists()) return this.newNode()

        return this.newNode(this.siblings()[this.getIndex() + 1])
    }

    /* ------------------------------------------------------------------------------------------------------------------------------- siblings -+- */
    /**
     * @returns {UiNode[]}
     */
    siblings(flushCache = false) {
        if (!this.exists()) return []

        return this.parent().children()
    }

    /* ------------------------------------------------------------------------------------------------------------------------------- children -+- */
    /**
     * @returns {UiNode[]}
     */
    children() {
        if (!this.exists()) return []

        let selection = this.node.querySelector('.ygg-sub-nodes').children

        return Array.from(selection).map(element => new UiNode(element, this))
    }

    /* ---------------------------------------------------------------------------------------------------------------------------- first Child -+- */
    firstChild() {
        let children = this.children()
        if (!children.length) return this.newNode()

        return children[0]
    }

    /* ----------------------------------------------------------------------------------------------------------------------------- last Child -+- */
    lastChild() {
        let children = this.children()
        if (!children.length) return this.newNode()

        return children[children.length - 1]
    }

    /* --------------------------------------------------------------------------------------------------------------------------------- exists -+- */
    exists() {
        return !!this.node
    }

    isRoot() {
        return this.node.classList.contains('ygg-root')
    }

    /* --------------------------------------------------------------------------------------------------------------------------- has Children -+- */
    hasChildren() {
        let children = this.children()
        return !!children.length
    }

    /* ------------------------------------------------------------------------------------------------------------------------ get Title Input -+- */
    getTitleInput() {
        return this.node.querySelector('.title-input')
    }

    /* ------------------------------------------------------------------------------------------------------------------------------ get Value -+- */
    /**
     * @returns {string}
     */
    getValue() {
        return this.getTitleInput().innerHTML
    }

    /* ------------------------------------------------------------------------------------------------------------------------------ get Index -+- */
    getIndex() {
        return this.siblings().findIndex(sibling => sibling.linkId === this.linkId)
    }


    /* -------------------------------------------------------------------------------------------------------------------------------------------- */
    /* ---------------------------------------------------------------------------------------------------------------------- CHAINABLE METHODS -+- */
    /* -------------------------------------------------------------------------------------------------------------------------------------------- */

    /* ---------------------------------------------------------------------------------------------------------------------------- move To Pos -+- */
    moveToPos(pos) {
        let siblings = this.siblings()

        let newOrderNumber = UiNode.getOrderForPos(siblings, pos)

        if (newOrderNumber === false) {
            arrayMove(siblings, this.getIndex(), pos)
            console.table({
                current: this.getIndex(),
                next: pos,
                siblings: siblings,
            })

            UiNode.redistributeOrderNumbers(siblings)

        } else if (this.getAttributes().order !== newOrderNumber) {
            let collidingNode = this.siblings().find(sibling => sibling.getAttributes().order === newOrderNumber)

            // handle order number collision
            if (collidingNode) {
                // exchange order numbers
                store.dispatch(node.actions.linkUpdate({
                    id: collidingNode.linkId,
                    attributes: {order: this.getAttributes().order},
                }))
            }

            // update the order number
            store.dispatch(node.actions.linkUpdate({
                id: this.linkId,
                attributes: {order: newOrderNumber},
            }))
        }

        return this
    }

    /* ---------------------------------------------------------------------------------------------------------------------------------- focus -+- */
    focus() {
        let titleInput = this.getTitleInput()

        titleInput.focus()

        return this
    }


    /* -------------------------------------------------------------------------------------------------------------------------------------------- */
    /* -------------------------------------------------------------------------------------------------------------------------------- HELPERS -+- */
    /* -------------------------------------------------------------------------------------------------------------------------------------------- */

    /* -------------------------------------------------------------------------------------------------------------------- toggle Sub Nodes -+- */
    toggleSubNodes() {
        store.dispatch(node.actions.linkUpdate({
            id: this.linkId,
            attributes: {is_extended: !this.getAttributes().is_extended},
        }))
    }

    /* -------------------------------------------------------------------------------------------------------------------------- add To Parent -+- */
    /**
     * @param {UiNode} newParent
     * @param {number} pos
     * @param {boolean} clone
     * @returns {string} new hash
     */
    addToParent(newParent, pos, clone = false) {

        // continue only if parent exists and it is not already current parent
        if (!newParent.exists() || newParent.nodeId === this.getAttributes().parent_id) return false

        const newOrder = pos ? UiNode.getOrderForPos(newParent.children(), pos) : 0

        if (clone) {
            const newId = uuidv4()
            store.dispatch(node.actions.linkCreate({
                id: newId,
                attributes: {
                    parent_id: newParent.nodeId,
                    order: newOrder,
                    child_id: this.nodeId,
                },
            }))

        } else {
            store.dispatch(node.actions.linkUpdate({
                id: this.linkId,
                attributes: {
                    order: newOrder,
                    parent_id: newParent.nodeId,
                },
            }))
        }

        return hash53(newParent.instanceHash + this.nodeId)
    }

    /* --------------------------------------------------------------------------------------------------------------------------- insert After -+- */
    /**
     * @param {UiNode} target
     * @param {boolean} clone
     * @returns {string} new hash
     */
    insertAfter(target, clone = false) {
        return this.addToParent(target.parent(), target.getIndex() + 1, clone)
    }


    /* -------------------------------------------------------------------------------------------------------------------------------------------- */
    /* ------------------------------------------------------------------------------------------------------------------------- STATIC METHODS -+- */
    /* -------------------------------------------------------------------------------------------------------------------------------------------- */

    /* ---------------------------------------------------------------------------------------------------------------------- get Order For Pos -+- */
    static getOrderForPos(items, index) {
        if (index < 0) index = items.length + index + 1

        // lower boundary of order number
        let start = index === 0 ? 0 : items[index - 1].getAttributes().order
        // upper boundary of order number
        let end   = index === items.length ? 65535 : items[index].getAttributes().order

        start = Number(start)
        end   = Number(end)

        if (Math.abs(end - start) < 2) return false

        return String(start + ((end - start) / 2))
    }

    /* ------------------------------------------------------------------------------------------------------------- redistribute Order Numbers -+- */
    /**
     * @param {UiNode[]} items
     */
    static redistributeOrderNumbers(items) {
        // Leave some margin at the first and last positions.
        // For that, we add 4 to numItems.
        // We will start iterating from 2, therefore we will have some number range near the min and max.
        const numItems = items.length + 4
        const step     = Math.floor(65535 / (numItems - 1))

        LogFancy.tag('nodeMove').big().orange('Redistributing order numbers!')

        for (let i = 0; i < items.length; i++) {
            store.dispatch(node.actions.linkUpdate({
                id: items[i].linkId,
                attributes: {order: String((i + 2) * step)},
            }))
        }
    }
}
