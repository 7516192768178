import React from 'react'

/**
 *
 * @param size use font awesome sizing utils: `xs`, `sm`, `lg`, `2x` - `10x`
 * @returns {XML}
 * @constructor
 * @see https://fontawesome.com/how-to-use/on-the-web/styling/sizing-icons
 */
const Spinner = ({size = '3x'}) => {
    
    let sizeClass = size === 'md' ? '' : 'fa-' + size
    
    return (
        <div className="w-100 text-center mt-2 mb-3">
            <i className={'fas fa-spinner fa-spin ' + sizeClass}></i>
        </div>
    )
}

export default Spinner